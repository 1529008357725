.ticker-container {
    width: 100%;
    overflow: hidden;
    background-color: #d83523cf; /* Constant background color */
    color: white;
  }
  
  .ticker {
    display: flex;
    animation: ticker 30s linear infinite; /* Slower animation */
  }
  
  .ticker-item {
    white-space: nowrap;
    padding: 10px 30px;
  }
  
  /* Keyframes for ticker animation */
  @keyframes ticker {
    0%   { transform: translate3d(100%, 0, 0); }
    100% { transform: translate3d(-100%, 0, 0); }
  }
  