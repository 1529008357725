.heroContainer {
    background-image: url('../assets/logo/Kanya_Shiksha_Parishar.png'); /* Path to your background image */
    background-size: cover;
    /* background-position: ; */
    opacity: 0.92;
    height: 60vh; /* Adjust height as needed */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: rgb(251, 245, 245); /* Adjust text color based on background */
    text-align: center;
    padding: 16px; /* Adjust as needed */
}

.heroCard {
    /* background: rgba(255, 255, 255, 0.5); 50% transparent white */
    /* Uncomment the next line for a blue gradient background */
    /* background: linear-gradient(135deg, rgba(88, 197, 112, 0.95) 0%, rgba(22, 149, 49, 0.95) 100%); */
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    /* margin: 20px; Adjust as needed */
    width: 45%; /*Adjust width as needed */
    height: 25%;
    max-width: 50%; /*Prevents the card from being too wide */
    box-sizing: border-box; /*Makes sure padding doesn't affect the width */
    z-index: 2; /* Ensures the card is above the overlay */
    position: absolute;
    top: 15%;
    /* bottom: 50px; */
}

/* Overlay to make text more readable */
.heroOverlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black overlay */
    z-index: 1; /* Under the card */
}

.heroText {
    font-size: 200rem; /* Increased font size */
    font-weight: bold;
    color: #f2f7f5; /* Dark color for contrast */
    text-shadow: #a2efd5;
    margin-bottom: 0.5em; /* Space between the title and tagline */
}

.heroTextMobile {
    font-size: 2.2rem; /* Smaller font size for mobile */
}

.tagline {
    font-size: 1.8rem; /* Increased font size */
    font-weight: bold;
    color: #fcfafa; /* Dark color for contrast */
}

.taglineMobile {
    font-size: 1.0rem; /* Smaller font size for mobile */
}

.ctaButton {
    margin-top: 24px; /* Space above the button */
    font-weight: bold; /* Optional: Makes the button text bold */
}
