/* NavigationBar.css */

.top-bar {
    background: linear-gradient(45deg, #359fdde3 30%, #80D0C7 90%); /* Gradient background */
    color: white;
    min-height: 80px;
    padding: 0 10px; /* Reduced horizontal padding */
}

.nav {
    width: 100%;
    display: flex;
    justify-content: space-between; /* Space distribution */
}

.nav-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: flex;
    width: 100%; /* Full width to utilize space */
    justify-content: space-between; /* Space out items */
    align-items: center; /* Center items vertically */
}

.nav-list li {
    display: inline;
}

.nav-list li a {
    display: block;
    color: white;
    text-align: center;
    padding: 14px 16px; /* Adjusted padding */
    text-decoration: none;
    transition: background-color 0.3s, color 0.3s;
    font-size: 1.1em; /* Increased font size */
}

.nav-list li a:hover, .nav-list li a:focus {
    background-color: #4CAF50; /* Green color on hover/focus */
    color: white;
}

/* Styles for Material UI components */
.MuiAppBar-root {
    background: linear-gradient(45deg, #0093E9 30%, #80D0C7 90%); /* Gradient background */
    color: white;
    padding: 0 10px; /* Reduced horizontal padding */
}

.MuiToolbar-regular {
    min-height: 80px; /* Increased height */
    width: 100%;
    justify-content: space-between; /* Space distribution */
    align-items: center; /* Center items vertically */
}
