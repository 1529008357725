.file-viewer-page {
    display: flex;
    height: 100vh; /* Full height of the viewport */
}

.file-list {
    flex: 0 0 30%; /* 30% of the container width with no growth or shrink */
    overflow: auto;
    padding: 20px;
    border-right: 1px solid #ddd; /* Separator between the two sections */
}

.file-list table {
    width: 100%;
    border-collapse: collapse;
}

.file-list tr {
    cursor: pointer;
    padding: 10px;
    border-bottom: 1px solid #eee;
}

.file-list tr:hover {
    background-color: #f5f5f5;
}

.file-list td {
    padding: 10px;
}

.file-view-pane {
    flex: 1; /* Takes the remaining space */
    display: flex; /* Enables flexbox for centering */
    justify-content: center; /* Centers horizontally */
    align-items: center; /* Centers vertically */
    overflow: auto; /* Allows scrolling if the content is larger than the pane */
    padding: 20px; /* Consistent padding with the file-list */
    position: relative; /* For absolute positioning of the content */
}

.file-view-pane h3 {
    position: absolute; /* Positions the heading absolutely within the pane */
    top: 20px; /* Distance from the top */
    left: 20px; /* Distance from the left */
    width: calc(100% - 40px); /* Full width minus padding */
    text-align: center; /* Centers the text */
}

.file-view-pane .view-image {
    max-width: 100%; /* Limits the width to the parent container's width */
    max-height: 80vh; /* 80% of the viewport height to ensure it fits within the screen */
    object-fit: contain; /* Maintains the aspect ratio */
}
