/* General Footer Styles */
.info_section {
    padding: 40px 0;
    background: linear-gradient(45deg, #359fdde3 30%, #80D0C7 90%); /* Gradient background */
    color: #333; /* Dark text for readability */
}

.info_logo-box h2 {
    font-weight: bold;
    font-size: 1.5em;
    border: 2px solid #333;
    padding: 10px;
    border-radius: 8px;
    text-transform: uppercase;
    letter-spacing: 2px;
    color: #fff;
    background: linear-gradient(135deg, #007bff, #00bfff);
    display: inline-block;
    text-align: center;
    margin: 0 auto 20px;
    align-self: center;
}

.layout_padding-top {
    padding-top: 20px;
}

.layout_padding2 {
    padding: 20px 0;
}

.container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 15px;
}

.row {
    display: flex;
    flex-wrap: wrap;
}

.col-md-3 {
    width: 25%;
    padding: 0 15px;
    box-sizing: border-box;
}

/* Footer Content Styles */
.info_section h5 {
    font-size: 2em;
    margin-bottom: 15px;
    font-weight: bold;
}

.info_section p,
.info_section ul {
    font-size: 1.2em;
    line-height: 1.6;
}

.info_section ul {
    padding-left: 20px;
    list-style-type: none;
}

.info_section ul li {
    margin-bottom: 10px;
}

.info_section ul li a {
    color: #333;
    text-decoration: none;
    transition: color 0.3s ease;
}

.info_section ul li a:hover {
    color: #0056b3; /* Change hover color as needed */
}

/* Contact Form Styles */
.subscribe_container {
    margin-top: 20px;
}

.form_container form {
    display: flex;
    flex-direction: column;
}

.form_container input[type="email"] {
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ddd;
}

.form_container button {
    padding: 10px;
    background-color: #0056b3; /* Primary color */
    color: white;
    border: none;
    cursor: pointer;
}

.form_container button:hover {
    background-color: #003580; /* Darken on hover */
}

/* Social Media Icons */
.social_container {
    text-align: center;
    margin-top: 20px;
}

.social-box a {
    display: inline-block;
    margin: 0 10px;
}

.social-box img {
    width: 30px; /* Adjust size as needed */
    height: auto;
}

/* Bottom Footer Section */
.footer_section {
    background-color: #272323; /* Dark background */
    color: white;
    text-align: center;
    padding: 10px 0;
}

.footer_section a {
    color: #0096c7;
    text-decoration: none;
}

.footer_section a:hover {
    color: #fff;
}
