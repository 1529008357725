.video-player-page {
    display: flex;
    height: 100vh;
}

.video-list {
    flex: 20%;
    overflow-y: auto; /* Enable scrolling for long lists */
    padding: 10px;
    border-right: 1px solid #ddd;
}

.video-item {
    cursor: pointer;
    padding: 10px;
    border-bottom: 1px solid #eee;
    display: flex;
    align-items: center;
}

.video-item:hover {
    background-color: #f5f5f5;
}

.thumbnail {
    width: 100%; /* Thumbnail takes full width of its container */
    height: auto;
    border-radius: 5px;
}

.video-pane {
    flex: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
}

.youtube-player {
    width: 90%; /* Increase width to 90% of its container */
    height: auto;
}
