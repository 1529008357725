.separator-component {
    width: 100%;
    padding: 20px 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.separator-line {
    height: 3px; /* A thicker line */
    width: 95%; /* Adjust the width as needed */
    background: linear-gradient(to right, #0056b3, #0096c7); /* Gradient from school colors */
    position: relative;
    border-radius: 1.5px; /* Slightly rounded corners */
}

.separator-line::after {
    content: '';
    position: absolute;
    left: 50%;
    top: -6px; /* Adjust to center vertically on the line */
    height: 18px; /* The height of the decorative element */
    width: 18px; /* The width of the decorative element */
    background-color: #180c0c; /* A contrasting color for the decorative element */
    transform: translateX(-50%) rotate(45deg); /* Center and rotate to form a diamond */
    border-radius: 2px; /* Slightly rounded corners for the diamond */
}
