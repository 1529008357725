/* General Styles for the Image Search Page */
.image-search-page {
    font-family: Arial, sans-serif; /* You can change the font as per your preference */
    padding: 20px;
    background-color: #f5f5f5; /* Light grey background */
    color: #333; /* Dark text for better readability */
    max-width: 800px;
    margin: 0 auto;
}

.image-search-page h1 {
    text-align: center;
    margin-bottom: 30px;
}

/* Styling for input, select, and button */
.search-controls {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px; /* Adjust the space between controls */
    margin-bottom: 20px;
}

.search-controls input[type="number"],
.search-controls select {
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 1em;
}

.search-controls button {
    padding: 10px 15px;
    background-color: #0056b3; /* Primary color */
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1em;
}

.search-controls button:hover {
    background-color: #003580; /* Darken on hover */
}

/* Image Display */
.image-display {
    text-align: center;
}

.image-display img {
    max-width: 100%;
    height: max-content;
    border-radius: 10px;
    margin-top: 20px;
}

/* Message Display */
.message {
    text-align: center;
    font-size: 1em;
    color: red;
}

.error-message {
    color: #ff6b6b; /* Bright red color for visibility */
    background-color: #ffe5e5; /* Light red background for contrast */
    border: 1px solid #ff6b6b;
    border-radius: 5px;
    padding: 10px;
    margin-top: 20px;
    font-size: 1.1em;
    display: inline-block; /* Makes the background only as wide as the content */
}