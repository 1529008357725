/* General styles for all sections */
.section {
    padding: 60px 20px; /* Increased padding for more space around sections */
}

.container {
    margin: 0 auto;
    padding: 0 20px; /* Increased padding for more space inside the container */
}

.row {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px;
}

.col-md-6 {
    width: 50%;
    padding: 0 15px;
    display: flex;
    width: 100%;
    align-items: center; /* Vertically center the content */
    justify-content: center; /* Horizontally center the content */
}

/* Specific styles for image and detail containers */
.img-container,


.img-container img,

.detail-box img {
    max-width: 100%;
    height: auto;
    border-radius: 15px; /* Smooth rounded corners for images */
}



.detail-box {
    background: #ffffff; /* Solid white background */
    align-items: center;
    justify-content: center;
    padding: 40px; /* Maintain padding for internal space */
    text-align: center; 
    border-radius: 20px; /* Maintain rounded corners */
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.12); /* Maintain shadow for depth */
    margin-top: 30px; /* Maintain top margin for spacing */
    transition: transform 0.3s ease, box-shadow 0.3s ease; /* Maintain smooth transition for hover effects */
    width: 100%; /* Set width to fill the column */
    margin: 30px 0; /* Adjust margin to only top and bottom for vertical spacing */
}


.detail-box:hover {
    transform: translateY(-5px); /* Slight lift effect on hover */
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.18); /* Enhanced shadow on hover for depth */
}

/* Content styles */
.detail-box h3 {
    font-size: 2.2em; /* Slightly larger font size for headings */
    margin-bottom: 25px; /* More space below headings */
    color: #007bff; /* Heading color for emphasis */
}

.detail-box p,
.detail-box ul {
    font-size: 1.05em; /* Slightly larger font size for readability */
    line-height: 1.7; /* Increased line height for readability */
    color: #333; /* Darker text color for contrast */
}

.detail-box ul {
    padding-left: 40px; /* Padding for bullet points */
    list-style-type: disc; /* Disc style for bullets */
}

.detail-box li {
    margin-bottom: 15px; /* More space between list items */
}

/* Call to action button */
.call_to-btn {
    background-color: #0056b3; /* Primary button color */
    color: white;
    padding: 12px 25px; /* More padding for a larger button */
    border-radius: 8px; /* Rounded corners for the button */
    text-decoration: none;
    display: inline-block;
    margin-top: 25px; /* Space above the button */
    transition: background-color 0.3s ease; /* Smooth transition for hover effect */
}

.call_to-btn:hover {
    background-color: #003580; /* Darker color on hover for button */
}

/* Specific styles for H2 elements */
h2 {
    font-weight: bold; /* Bold text for emphasis */
    font-size: 2em; /* Adjusted font size for visibility */
    color: #fff; /* White text for contrast */
    background: linear-gradient(135deg, #007bff, #00bfff); /* Blue gradient background */
    border: none; /* Removed border for a cleaner look */
    padding: 20px 30px; /* Adjusted padding for balance */
    border-radius: 12px; /* Smooth rounded corners */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
    text-transform: uppercase; /* Uppercase text for stylistic choice */
    letter-spacing: 2px; /* Space between letters for readability */
    display: inline-block; /* Inline-block for natural width */
    text-align: center; /* Center-aligned text */
    margin: 20px auto; /* Centered margin with more space */
    max-width: 90%; /* Adjusted max-width for better responsiveness */
}
