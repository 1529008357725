/* Header.css */

.header {
    background-color: #4CAF50;
    color: white;
    padding: 10px 0;
    text-align: center;
    
}

.header h1 {
    margin: 0;
}
